import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as Rx from "rxjs";
import { catchError, finalize, tap } from 'rxjs/operators';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import * as Sentry from "@sentry/angular";
import { AngularFireStorage } from "@angular/fire/compat/storage";


@Injectable({
  providedIn: 'root'
})

export class UserService {
  cityName = new Rx.BehaviorSubject({});
  profileUpdated = new Rx.BehaviorSubject(false);
  cartUpdated = new Rx.BehaviorSubject(false);
  appSettings = new Rx.BehaviorSubject({});
  currentUser = new Rx.BehaviorSubject({});
  
  cartObject: any = {};
  uid:any;
  mobile : any;
  version : any = "";
  userId : any;
  email : any;
  
  //  url: any='https://spotyourdeal.com'
 url: any='https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api'
  _id: any;
  // url: any='http://localhost:3000'
  // url: any='https://8066-103-49-235-141.ngrok.io'
  constructor(
    public http: HttpClient,
    public afs: AngularFirestore,
    public appVersion: AppVersion,
    public afAuth: AngularFireAuth,
    public storage:AngularFireStorage
  ) {}

  async initialize(uid) {
    let data={
      uid : uid
    }
    this.getUserDetails(data).subscribe((res:any)=>{
      console.log(res);
      if(res){
        this.userId=res._id
        this.email=res.email1
        this.mobile = res.phone
        Sentry.setUser({ phone: res.phone, userId: res._id });
        Sentry.setTag("phone", this.mobile);
        Sentry.setTag("userId", res._id );
        localStorage.setItem("userId",this.userId)
        this.currentUser.next(this.userId);

        // let name = 'CustomError';
        // // let message = "test message" || '';
        // var error = new Error();
        // error.name = name;
        // error.message = JSON.stringify(res);
        // Sentry.captureException(error);
      }
      //this.mobile=res.phoneNumber
    })

    this.appVersion.getVersionNumber().then(version => {
      this.version = version;
      Sentry.setTag("appVersion", version );
    });

    this.afs.collection('settings').doc('spot').valueChanges().subscribe((resp:any)=>{
      console.log("this.afs.collection('settings') ", resp);
      this.appSettings.next(resp);
    })
  }

  sendException(error){
    Sentry.captureException(error);
  }

  setContext(contextName, contextObject){
    Sentry.setContext(contextName, contextObject);
  }

  registration(data){
    return this.http.post(this.url+'/registration',data);
  }

  getUserDetails(data){
    return this.http.post(this.url+'/getUserDetails',data);
  }

  updateDetails(data){
    return this.http.post(this.url+'/updateDetails',data)
  }

  onlineCheckout(data) {
    return this.http.post(this.url + '/checkoutVerify', data)
  }
  orderCancle(data){
    return this.http.post(this.url + '/customerCancelOrder', data)
  }
  registerAsWholseller(data){
    return this.http.post(this.url + '/addretailerBuyerdetails', data)
  }
  setLogData(id, data:any) {
    data.timestamp = new Date().getTime();
    this.afs.collection("logs").doc(id).set(data)
  }
  verifyAddMoney(data) {
    return this.http.post(this.url + '/checkoutVerify1', data)
  }
  updateLogData(id, data:any) {
    // let id =  this.afs.createId();
    this.afs.collection("logs").doc(id).update(data)
  }
  collectSpotCoin(postData: any){
    return this.http.post(this.url+'/distributeSpotCoinsMLM',postData)
  }
  uploadImage(event) {

    return new Promise(async (resolve, reject) => {

      var n = Date.now();

      const file = event.target.files[0];

      console.log("Service file image upload call function",file)

      const filePath = `productImages/${n}`;

      const fileRef = this.storage.ref(filePath);

      const task = this.storage.upload(`productImages/${n}`, file);



      task.snapshotChanges().pipe(

          finalize(() => {

            fileRef.getDownloadURL().subscribe(resp => {
              console.log(resp,"Final response from firebase");
              resolve(resp);

            

            });

            // this.loadingProvider.dismiss();

          })

        )

        .subscribe()

    })
  }

}
