import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { UserService } from './services/user.service';
import firebase from 'firebase/compat/app';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { FirebaseAuthentication } from '@awesome-cordova-plugins/firebase-authentication/ngx';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as Sentry from "@sentry/angular";
import OneSignal from 'onesignal-cordova-plugin';

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userId: any;
  firebaseUser: any;
  cart: any = [];
  wishlist: any = [];
  constructor(
    public userService: UserService,
    public firebaseAuthentication: FirebaseAuthentication,
    // public studentService: StudentService,
    // public postService: PostService,
    // public employerService: EmployerService,
    public afAuth: AngularFireAuth,
    public splashScreen: SplashScreen,
    public platform: Platform,
    public statusBar: StatusBar,
    public router: Router
  ) {
    var check = JSON.parse(localStorage.getItem('cart'))

    if (!check) {

      this.cart = [];
      localStorage.setItem('cart', JSON.stringify(this.cart))

    }
    var checkk = JSON.parse(localStorage.getItem('wishlist'))
    if (!checkk) {
      this.wishlist = [];
      localStorage.setItem('wishlist', JSON.stringify(this.wishlist))
    }
    this.platform.ready().then(() => {

      this.userService.currentUser.subscribe((userId: any)=>{
        console.log(userId,"hello")
        // this.setupNotifications(userId)
      })
      // try {
      //   this.aFunctionThatMightFail();
      // } catch (err) {

      // }

      // var Sentry = cordova.require("sentry-cordova.Sentry");
      // Sentry.init({ dsn: 'https://afd7a725a74b422c8aa0e821e48c4060@o1237190.ingest.sentry.io/6387538' });

      // let uid = localStorage.getItem("uid");
      // if (uid) {
      //   console.log("localStorage uid ", uid)
      //   this.userService.uid = firebaseUser.uid;
      //   this.userService.initialize(firebaseUser.uid);
      //   if (firebaseUser.displayName) {
      //     this.router.navigate(['/tabs/feeds'])
      //   } else {
      //     this.router.navigate(['/registration'])
      //   }

      // } else {

      //   this.authService.initialize();
      //   // .then((state: any) => {
      //   //   if (!state.status) {
      //   //     // this.splashScreen.hide();
      //   //     this.router.navigate(['login']);
      //   //   }
      //   // })

      //   this.userService.currentUser.subscribe((user: any) => {
      //     if (user.uid) {
      //       // localStorage.setItem("uid", user.uid);
      //       // this.checkAuth(user);
      //     }
      //   });
      // }



      // this.statusBar.styleDefault();
      // this.statusBar.backgroundColorByHexString('#f6f6f6');
      this.statusBar.backgroundColorByHexString('#f19155');
      // this.statusBar.styleBlackTranslucent();
      // this.authService.initialize();



      this.firebaseAuthentication.onAuthStateChanged().subscribe(firebaseUser => {
        console.log("firebaseAuthentication ", firebaseUser)
        if (firebaseUser) {
          this.userService.uid = firebaseUser.uid;
          this.userService.initialize(firebaseUser.uid);
          if (firebaseUser.displayName) {
            this.router.navigate(['/'])
          } else {
            this.router.navigate(['/registration'])
          }
          console.log(this.userService.uid);
          // this.logOutFirebase();
        } else {
          // this.router.navigate(['/'])
        }

      })

      this.afAuth.onAuthStateChanged(firebaseUser => {
        console.log("firebaseUser ", firebaseUser)
        if (firebaseUser) {
          this.userService.uid = firebaseUser.uid;
          this.userService.initialize(firebaseUser.uid);
          if (firebaseUser.displayName) {
            this.router.navigate(['/'])
          } else {
            this.router.navigate(['/registration'])
          }
          console.log(this.userService.uid);
          // this.logOutFirebase();
        } else {
          // this.router.navigate(['/'])
        }
      }, error => {

      })

    })
  }

  logOutFirebase = () => {
    firebase.auth().signOut();
  }


  async setupNotifications(userID) {
    let that = this;
    console.log('setupNotifications userID', userID);
    await OneSignal.setAppId('b1494086-97df-4232-b59c-3933a635c919')

    OneSignal.setNotificationOpenedHandler(function(jsonData) {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));

      let data: any = jsonData.notification.additionalData;
      console.log('notificationOpenedCallback: ' + data);
      if (data.type == 'comment') {
        // that.openOnePost(data.post)
        // that.userService.changeTab.next({ tab: 'likes' });

      }
      if (data.type == 'likes') {
        // that.userService.changeTab.next({ tab: 'likes' });
      }
      if (data.type == 'messages') {
        // that.userService.changeTab.next({ tab: 'messages' });
      }
    });

    OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      //alert("User accepted notifications: " + accepted);
    });

    OneSignal.sendTag('userID', userID)
    OneSignal.setExternalUserId(userID)
  }


}
